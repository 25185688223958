import * as React from "react";
export function Earnings(props) {
    return (<>
            <div className="h-10 border-l border-gray-900 mx-4"></div>

            <div className="w-1/2">
                <div className="text-base text-gray-900 font-light">
                    Earnings
                </div>
                <div className="text-xl font-medium">
                    ${props.earnings.toLocaleString()}
                </div>
            </div>
        </>);
}
